
import { extend } from 'flarum/extend';
import HeaderPrimary from 'flarum/components/HeaderPrimary';
import HeaderSecondary from 'flarum/components/HeaderSecondary';

import Component from 'flarum/Component';
import Button from 'flarum/components/Button';
import LogInModal from 'flarum/components/LogInModal';
import SignUpModal from 'flarum/components/SignUpModal';
import SessionDropdown from 'flarum/components/SessionDropdown';
import SelectDropdown from 'flarum/components/SelectDropdown';
import NotificationsDropdown from 'flarum/components/NotificationsDropdown';

app.initializers.add('sba/menu', function() {
	/* Debug Default label */
	app.data.locales['fr'] = 'Français';

	/* Translations */
	var home, first, second, third, fourth, backTo, footer;
	switch (app.translator.locale) {
		case 'fr':
			home = 'Accueil Forum';
			backTo = '>> retour site:';
			first = {
				link: 'https://mamanboit.ch/8-12/',
				text: '8-12 ans',
			};
			second = {
				link: 'https://mamanboit.ch/13-20/',
				text: '12 ans et plus',
			};
			third = {
				link: 'https://mamanboit.ch/faq/',
				text: 'FAQ'
			};
			fourth = {
				link: 'https://mamanboit.ch/contact/',
				text: 'Contact'
			};
      footer = {
        first: {
          text: "Protection des données",
          link: "https://www.addictionsuisse.ch/protection-des-donnees/",
          click: ""
        },
        second: {
          text: "Gestion des cookies",
          link: "#",
          click: "UC_UI.showSecondLayer();"
        }
      };
      break;
		case 'de':
			home = 'Forum-Startseite';
			backTo = '>> zurück zur Seite:';
			first = {
				text: '8-12 Jahre',
				link: 'https://mamatrinkt.ch/8-12/'
			};
			second = {
				text: '12 Jahre und älter',
				link: 'https://mamatrinkt.ch/13-20/'
			};
			third = {
				link: 'https://mamatrinkt.ch/fragen/',
				text: 'FAQ'
			};
			fourth = {
				link: 'https://mamatrinkt.ch/contact/',
				text: 'Kontakt'
			};
      footer = {
        first: {
          text: "Datenschutz",
          link: "https://www.suchtschweiz.ch/datenschutz/",
          click: ""
        },
        second: {
          text: "Cookie-Einstellungen",
          link: "#",
          click: "UC_UI.showSecondLayer();"
        }
      };
			break;
		default:
			home = 'Accueil Forum';
			backTo = '>> retour site:';
			first = {
				link: 'https://mamanboit.ch/8-12/',
				text: '8-12 ans',
			};
			second = {
				link: 'https://mamanboit.ch/13-20/',
				text: '12 ans et plus',
			};
			third = {
				link: 'https://mamanboit.ch/faq/',
				text: 'FAQ'
			};
			fourth = {
				link: 'https://mamanboit.ch/contact/',
				text: 'Contact'
			};
      footer = {
        first: {
          text: "Protection des données",
          link: "https://www.addictionsuisse.ch/protection-des-donnees/",
          click: ""
        },
        second: {
          text: "Gestion des cookies",
          link: "#",
          click: "UC_UI.showSecondLayer();"
        }
      };
	}

	extend(HeaderPrimary.prototype, 'items', function(items) {
	  if (app.translator.locale == 'de') {
		$("#home-link > img").attr('src', '/assets/logo_de.svg');
	  }

	  items.add('link0', <a href="/" id="home-link-sba">{home}</a>);
	  items.add('link1', <span className="text">{backTo} <a href={first.link}>{first.text}</a></span>);
	  items.add('link2', <span className="text">/<a href={second.link}>{second.text}</a></span>);
	  items.add('link3', <a href={third.link}>{third.text}</a>);
	  items.add('link4', <a href={fourth.link}>{fourth.text}</a>);
	  noReload();
	});


	extend(HeaderSecondary.prototype, 'items', function(items) {
		items.remove('locale');
		items.remove('notifications');
		items.remove('session');
		items.remove('signUp');
		items.remove('logIn');

	    if (app.forum.attribute("showLanguageSelector") && Object.keys(app.data.locales).length > 1) {
	      const locales = [];
	      for (const locale in app.data.locales) {
	        locales.push(Button.component({
	          active: app.data.locale === locale,
	          children: app.data.locales[locale],
	          icon: app.data.locale === locale ? 'fas fa-check' : true,
	          onclick: () => {
		        let url = window.location.protocol + '//' + window.location.hostname;
				let redirect = url;
				switch (locale) {
				    case 'de':
				    	redirect = url.replace(/mamanboit\./i, 'mamatrinkt.');
					    break;
				    case 'fr':
				    	redirect = url.replace(/mamatrinkt\./i, 'mamanboit.');
					    break;
				    default:
				    	redirect = url.replace(/mamatrinkt\./i, 'mamanboit.');
					    break;
			    }
	            window.location.href = redirect;
	          }
	        }));
	      }

	      items.add('locale', SelectDropdown.component({
	        children: locales,
	        buttonClassName: 'Button Button--link'
	      }), 20);
	    }

	    if (app.session.user) {
	      items.add('notifications', NotificationsDropdown.component(), 10);
	      items.add('session', SessionDropdown.component(), 0);
	    } else {
	      if (app.forum.attribute('allowSignUp')) {
	        items.add('signUp',
	          Button.component({
	            children: app.translator.trans('core.forum.header.sign_up_link'),
	            className: 'Button Button--link',
	            onclick: () => app.modal.show(new SignUpModal())
	          }), 10
	        );
	      }

	      items.add('logIn',
	        Button.component({
	          children: app.translator.trans('core.forum.header.log_in_link'),
	          className: 'Button Button--link',
	          onclick: () => app.modal.show(new LogInModal())
	        }), 0
	      );
	    }

    });


	$(document).ready(function () {
		noReload();
    $("#footer-menu").append('<a href="'+footer.first.link+'" onclick="'+footer.first.click+'">'+footer.first.text+'</a>');
    $("#footer-menu").append('<a href="'+footer.second.link+'" onclick="'+footer.second.click+'">'+footer.second.text+'</a>');
	});

	function noReload() {
		// Route the home link back home when clicked. We do not want it to register
	    // if the user is opening it in a new tab, however.
	    $('#home-link-sba').click(e => {
	      if (e.ctrlKey || e.metaKey || e.which === 2) return;
	      e.preventDefault();
	      app.history.home();

	      // Reload the current user so that their unread notification count is refreshed.
	      if (app.session.user) {
	        app.store.find('users', app.session.user.id());
	        m.redraw();
	      }
	      noReload();
	    });
	}
});
