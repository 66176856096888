import { extend } from 'flarum/extend';
import Model from 'flarum/Model';
import Tag from 'flarum/tags/models/Tag';
import TagsPage from 'flarum/tags/components/TagsPage';

Tag.prototype.locale = Model.attribute('locale');

extend(TagsPage.prototype, 'init', function () {
	for (var i = 0; i < this.tags.length; i++) {
		if (this.tags[i].data.attributes.locale != null && this.tags[i].data.attributes.locale != '' && this.tags[i].data.attributes.locale != app.translator.locale) {
			this.tags.splice(i, 1);
		}
	}
});