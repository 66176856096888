
import { extend } from 'flarum/extend';
import IndexPage from 'flarum/components/IndexPage';
import TagHero from 'flarum/tags/components/TagHero';

app.initializers.add('sba/banner', function() {
	
	/* Hide Welcome Banner by default */
	localStorage.setItem('welcomeHidden', 'true');
	
	extend(IndexPage.prototype, 'view', function (vdom) {
		if (vdom.children && vdom.children.splice) {
			const tag = this.currentTag();
			var insertTag = '';
			if (tag) {
				insertTag = TagHero.component({tag});
			}
			
			const insert = <div className="sbaHeader"><div id='sbaBanner' className={app.translator.locale}/>{insertTag}</div>;
			
			vdom.children.splice(1, 0, insert);
		}
	});
	
});