
import { extend } from 'flarum/extend';
import IndexPage from 'flarum/components/IndexPage';


extend(IndexPage.prototype, 'sidebarItems', function (items) {
	let security;
	switch (app.translator.locale) {
		case 'fr': 
			security = {
				first: 'Ce forum est un espace de discussion public. Il ne permet pas les discussions privées. Pour des raisons de sécurité, ',
				bold: 'ne diffuse jamais ton adresse e-mail ou les comptes de tes réseaux sociaux sur le forum.',
				second: ' Les commentaires avec des informations personnelles seront supprimés.'
			};
			break;
		case 'de':
			security = {
				first: 'Dieses Forum ist eine öffentliche Austauschplattform. Private Chats sind hier nicht möglich. Aus Sicherheitsgründen ',
				bold: 'solltest du im Forum nie deine E-Mail Adresse oder Infos für Soziale Medien teilen.',
				second: ' Kommentare mit persönlichen Informationen werden gelöscht.'
			};
			break;
		default:
			security = {
				first: 'Ce forum est un espace de discussion public. Il ne permet pas les discussions privées. Pour des raisons de sécurité, ',
				bold: 'ne diffuse jamais ton adresse e-mail ou les comptes de tes réseaux sociaux sur le forum.',
				second: ' Les commentaires avec des informations personnelles seront supprimés.'
			};
	}
	
	items.add('security', <div className="security Alert">{security.first}<b>{security.bold}</b>{security.second}</div>);
});


