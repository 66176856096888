import Modal from 'flarum/components/Modal';
import Button from 'flarum/components/Button';

export default class MamanBoitUserModal extends Modal {
    init() {
        super.init();

        this.birthYear = this.props.user.birthYear();
        this.state = this.props.user.state();
        this.sexe = this.props.user.sexe();
    }

    className() {
        return 'MamanBoitUserModal Modal--small';
    }

    title() {
	    /* Translations */
		switch (app.translator.locale) {
			case 'fr':
				return 'Informations';
			case 'de':
				return 'Informationen';
			default:
				return 'Informations';
		}
    }

    content() {
	    /* Translations */
	    let submit, birthYear, state, sexe, other;
		switch (app.translator.locale) {
			case 'fr':
				submit = 'Enregistrer';
				birthYear = 'Année de naissance';
				state = 'Canton';
				sexe = {
					male: 'Garçon',
					female: 'Fille',
					other: 'Je ne me reconnais dans aucune de ces catégories',
					text: 'Sexe'
				};
				other = 'Autre pays';
			case 'de':
				submit = 'Speichern';
				birthYear = 'Geburtsjahr';
				state = 'Kanton';
				sexe = {
					male: 'Junge',
					female: 'Mädchen',
					other: 'Ich erkenne mich in diesen Kategorien nicht wieder',
					text: 'Geschlecht'
				};
				other = 'Anderes Land';
			default:
				submit = 'Enregistrer';
				birthYear = 'Année de naissance';
				state = 'Canton';
				sexe = {
					male: 'Garçon',
					female: 'Fille',
					other: 'Je ne me reconnais dans aucune de ces catégories',
					text: 'Sexe'
				};
				other = 'Autre pays';
		}
		
		
		let year = new Date().getFullYear();
		let birthYearOptions = [];
		for (var i = year; i > year - 80; i--) {
			birthYearOptions.push(i);
		}
		var user = this;
		let options = birthYearOptions.map(function (year, index) {
		    return m('option', { value: year, innerHTML: year, selected: user.birthYear == year });
	    });
		
        return (
            <div className="Modal-body">
                <div className="Form">
                
                	<div className="Form-group">
                		<label for="birthYear">{birthYear}</label>
                        <select className="FormControl" required name="birthYear" id="birthYear">
						  {options}
						</select>
                	</div>
                	
                	<div className="Form-group">
                		<label for="state">{state}</label>
                        <select className="FormControl" name="State" id="state">
							<option value="">{state.text}</option>
					      	<option selected={this.state == 'AG'} value="AG">Aargau</option>
					      	<option selected={this.state == 'AR'} value="AR">Appenzell Ausserrhoden</option>
					      	<option selected={this.state == 'AI'} value="AI">Appenzell Innerrhoden</option>
					      	<option selected={this.state == 'BL'} value="BL">Basel-Landschaft</option>
							<option selected={this.state == 'BS'} value="BS">Basel-Stadt</option>
					      	<option selected={this.state == 'BE'} value="BE">Bern</option>
					      	<option selected={this.state == 'FR'} value="FR">Fribourg - Freiburg</option>
					      	<option selected={this.state == 'GE'} value="GE">Genève</option>
					      	<option selected={this.state == 'GL'} value="GL">Glarus</option>
					      	<option selected={this.state == 'GR'} value="GR">Graubünden</option>
					      	<option selected={this.state == 'JU'} value="JU">Jura</option>
					      	<option selected={this.state == 'LU'} value="LU">Luzern</option>
					      	<option selected={this.state == 'NE'} value="NE">Neuchâtel</option>
					      	<option selected={this.state == 'NW'} value="NW">Nidwalden</option>
					      	<option selected={this.state == 'OW'} value="OW">Obwalden</option>
					      	<option selected={this.state == 'SH'} value="SH">Schaffhausen</option>
					      	<option selected={this.state == 'SZ'} value="SZ">Schwyz</option>
							<option selected={this.state == 'SO'} value="SO">Solothurn</option>
							<option selected={this.state == 'SG'} value="SG">St. Gallen</option>
							<option selected={this.state == 'TG'} value="TG">Thurgau</option>
							<option selected={this.state == 'TI'} value="TI">Ticino</option>
							<option selected={this.state == 'UR'} value="UR">Uri</option>
							<option selected={this.state == 'VS'} value="VS">Valais - Wallis</option>
							<option selected={this.state == 'VD'} value="VD">Vaud</option>
							<option selected={this.state == 'ZG'} value="ZG">Zug</option>
							<option selected={this.state == 'ZH'} value="ZH">Zürich</option>
							<option selected={this.state == 'OTHER'} value="OTHER">{other}</option>
						</select>
                	</div>
                	<div className="Form-group">
                		<label for="sexe">{sexe.text}</label>
						<div className="Form-group sexe">
							<label className="checkbox" for="sexeFemale"><input type="radio" checked={this.sexe == 'F'} className="FormRadio" value="F" name="sexe" id="sexeFemale"/> {sexe.female}</label>
							<label className="checkbox" for="sexeMale"><input type="radio" checked={this.sexe == 'M'} className="FormRadio" value="M" name="sexe" id="sexeMale"/> {sexe.male}</label>
							<label className="checkbox" for="sexeOther"><input type="radio" checked={this.sexe == 'O'} className="FormRadio" value="O" name="sexe" id="sexeOther"/> {sexe.other}</label>
						</div>
                	</div>
                    <div className="Form-group" id="submit-button-group">
                        {Button.component({
                            type: 'submit',
                            className: 'Button Button--primary EditMamanBoitUser-save',
                            loading: this.loading,
                            children: submit,
                        })}
                    </div>
                </div>
            </div>
        );
    }

	data() {
		this.birthYear = document.getElementById("birthYear").value;
		this.state = document.getElementById("state").value;
		if (document.getElementById("sexeMale").checked) {
			this.sexe = "M";
		} else if (document.getElementById("sexeFemale").checked) {
			this.sexe = "F";
		} else if (document.getElementById("sexeOther").checked) {
			this.sexe = "O";
		}
		
		return {
			birthYear: this.birthYear,
			state: this.state,
	        sexe: this.sexe
	    };
	}

    onsubmit(e) {
        e.preventDefault();

        this.loading = true;

        this.props.user
            .save(this.data(), { errorHandler: this.onerror.bind(this) })
            .then(this.hide.bind(this))
            .then($('#app').trigger('refreshMamanBoitUser', [this.data()]))
            .catch(() => {
                this.loading = false;
                m.redraw();
            });
    }
}