
import Model from 'flarum/Model';
import User from 'flarum/models/User';
import { extend } from 'flarum/extend';
import UserCard from 'flarum/components/UserCard';
import Badge from 'flarum/components/Badge';
import MamanBoitUserModal from './components/MamanBoitUserModal';

import SignUpModal from 'flarum/components/SignUpModal';
import EditUserModal from 'flarum/components/EditUserModal';


app.initializers.add('sba-mamanboit-user', function() {

	/* Translations */
	var birthYear, state, sexe, moderate, unknown, username, password, gdpr;
	switch (app.translator.locale) {
		case 'fr':
			username = {
				text: 'Sans espace ni accent'
			};
			password = {
				text: 'Au minimum 8 caractères'
			};
      gdpr = {
        text: {
          start: 'En créant un nouveau compte, vous êtes d’accord avec les dispositions relatives à la déclaration sur la',
          end: '.'
        },
        link: {
          text: 'protection des données',
          url: 'https://www.addictionsuisse.ch/protection-des-donnees/'
        }
      };
			birthYear = {
				text: 'Année de naissance'
			};
			state = {
				text: 'Canton',
				other: 'Autre pays'
			};
			sexe = {
				male: 'Garçon',
				female: 'Fille',
				other: 'Je ne me reconnais dans aucune de ces catégories',
				text: 'Sexe'
			};
			moderate = {
				text: 'Informations'
			};
			unknown = {
				text: 'Inconnu'
			};
			break;
		case 'de':
			username = {
				text: 'Ohne Leerzeichen oder Sonderzeichen'
			};
			password = {
				text: 'Mindestens 8 Zeichen'
			};
      gdpr = {
        text: {
          start: 'Bei Erstellung eines neuen Kontos erklären Sie sich mit den',
          end: ' einverstanden.'
        },
        link: {
          text: 'Datenschutzbestimmungen',
          url: 'https://www.suchtschweiz.ch/datenschutz/'
        }
      };
			birthYear = {
				text: 'Geburtsjahr'
			};
			state = {
				text: 'Kanton',
				other: 'Anderes Land'
			};
			sexe = {
				male: 'Junge',
				female: 'Mädchen',
				other: 'Ich erkenne mich in diesen Kategorien nicht wieder',
				text: 'Geschlecht'
			};
			moderate = {
				text: 'Informationen'
			};
			unknown = {
				text: 'Unbekannt'
			};
			break;
		default:
			username = {
				text: 'Sans espace ni accent'
			};
			password = {
				text: 'Au minimum 8 caractères'
			};
      gdpr = {
        text: {
          start: 'En créant un nouveau compte, vous êtes d’accord avec les dispositions relatives à la déclaration sur la',
          end: '.'
        },
        link: {
          text: 'protection des données',
          url: 'https://www.addictionsuisse.ch/protection-des-donnees/'
        }
      };
			birthYear = {
				text: 'Année de naissance'
			};
			state = {
				text: 'Canton',
				other: 'Autre pays'
			};
			sexe = {
				male: 'Garçon',
				female: 'Fille',
				other: 'Je ne me reconnais dans aucune de ces catégories',
				text: 'Sexe'
			};
			moderate = {
				text: 'Informations'
			};
			unknown = {
				text: 'Inconnu'
			};
	}

	User.prototype.birthYear = Model.attribute('birthYear');
	User.prototype.state = Model.attribute('state');
	User.prototype.sexe = Model.attribute('sexe');


	extend(UserCard.prototype, 'infoItems', function(items) {
        this.isSelf = app.session.user === this.props.user;
        this.canEdit = app.session.user ? app.session.user.data.attributes.canEdit : false;
        this.birthYear = this.props.user.birthYear();
        this.state = this.props.user.state();
        this.sexe = this.props.user.sexe();

        if (this.canEdit && !this.isSelf) {
            items.add(
                'settings mamanboitUser',
                Badge.component({
                    type: 'mamanboitUserModerate',
                    icon: 'fas fa-exclamation-triangle',
                    label: moderate.text,
                    onclick: () => {
                        app.modal.show(new MamanBoitUserModal({ user: this.props.user }));
                    },
                }),
                -1
            );
        }

        if (this.isSelf) {
            items.add(
                'settings birthYear',
                Badge.component({
                    type: 'birthYear birthYearSettings',
                    icon: 'fas fa-birthday-cake',
                    label: birthYear.text + ' : ' + (this.birthYear || unknown.text),
                    onclick: () => {
                        app.modal.show(new MamanBoitUserModal({ user: this.props.user }));
                    },
                }),
                -1
            );

            items.add(
                'settings state',
                Badge.component({
                    type: 'state stateSettings',
                    icon: 'fas fa-map-marker-alt',
                    label: state.text + ' : ' + (this.state || unknown.text),
                    onclick: () => {
                        app.modal.show(new MamanBoitUserModal({ user: this.props.user }));
                    },
                }),
                -1
            );

            items.add(
                'settings sexe',
                Badge.component({
                    type: 'sexe sexeSettings',
                    icon: 'fas fa-venus-mars',
                    label: sexe.text + ' : ' + (this.sexe || unknown.text),
                    onclick: () => {
                        app.modal.show(new MamanBoitUserModal({ user: this.props.user }));
                    },
                }),
                -1
            );
        }
	});


	extend(SignUpModal.prototype, 'fields', function (items) {

		let year = new Date().getFullYear();
		let birthYearOptions = [];
		for (var i = year; i > year - 80; i--) {
			birthYearOptions.push(i);
		}

		let options = birthYearOptions.map(function (year, index) {
		    return m('option', { value : year, innerHTML : year });
	    });

		items.add('birthYear', <div className="Form-group">
		<div className="usernameInstruction">{username.text}</div>
		<div className="Form-group">
		  <select className="FormControl" required name="birthYear" id="birthYear">
		    <option disabled selected>{birthYear.text}</option>
		    {options}
		  </select>
	    </div></div>, 30);

	    items.add('state', <div className="Form-group">
	      <select className="FormControl" name="State" id="state">
	        <option value="">{state.text}</option>
	      	<option value="AG">Aargau</option>
	      	<option value="AR">Appenzell Ausserrhoden</option>
	      	<option value="AI">Appenzell Innerrhoden</option>
	      	<option value="BL">Basel-Landschaft</option>
			<option value="BS">Basel-Stadt</option>
	      	<option value="BE">Bern</option>
	      	<option value="FR">Fribourg - Freiburg</option>
	      	<option value="GE">Genève</option>
	      	<option value="GL">Glarus</option>
	      	<option value="GR">Graubünden</option>
	      	<option value="JU">Jura</option>
	      	<option value="LU">Luzern</option>
	      	<option value="NE">Neuchâtel</option>
	      	<option value="NW">Nidwalden</option>
	      	<option value="OW">Obwalden</option>
	      	<option value="SH">Schaffhausen</option>
	      	<option value="SZ">Schwyz</option>
			<option value="SO">Solothurn</option>
			<option value="SG">St. Gallen</option>
			<option value="TG">Thurgau</option>
			<option value="TI">Ticino</option>
			<option value="UR">Uri</option>
			<option value="VS">Valais - Wallis</option>
			<option value="VD">Vaud</option>
			<option value="ZG">Zug</option>
			<option value="ZH">Zürich</option>
			<option value="OTHER">{state.other}</option>
	      </select>
	    </div>, 30);

	    items.add('sexe', <div className="Form-group sexe">
	    	<label className="checkbox" for="sexeFemale"><input type="radio" className="FormRadio" value="F" name="sexe" id="sexeFemale"/> {sexe.female}</label>
	    	<label className="checkbox" for="sexeMale"><input type="radio" className="FormRadio" value="M" name="sexe" id="sexeMale"/> {sexe.male}</label>
	    	<label className="checkbox" for="sexeOther"><input type="radio" className="FormRadio" value="O" name="sexe" id="sexeOther"/> {sexe.other}</label>
	    </div>, 30);

	    items.add('passwordInstruction', <div className="passwordInstruction">{password.text}</div>);

      items.add('gdprInstruction', <div className="gdprInstruction">
        {gdpr.text.start} <a href={gdpr.link.url} target="_blank">{gdpr.link.text}</a>{gdpr.text.end}
      </div>);
	});

	extend(SignUpModal.prototype, 'submitData', function (data) {
		data.birthYear = document.getElementById("birthYear").value;
		data.state = document.getElementById("state").value;
		if (document.getElementById("sexeMale").checked) {
			data.sexe = "M";
		} else if (document.getElementById("sexeFemale").checked) {
			data.sexe = "F";
		} else if (document.getElementById("sexeOther").checked) {
			data.sexe = "O";
		}

		data.registering = true;
		return data;
	});

	extend(EditUserModal.prototype, 'fields', function (items) {

		let year = new Date().getFullYear();
		let birthYearOptions = [];
		for (var i = year; i > year - 80; i--) {
			birthYearOptions.push(i);
		}
		var user = this.props.user;
		let options = birthYearOptions.map(function (year, index) {
		    return m('option', { value: year, innerHTML: year, selected: user.birthYear() == year });
	    });

		items.add('birthYear', <div className="Form-group">
		<div className="usernameInstructionEdit">{username.text}</div>
		<div className="Form-group">
		  <label for="birthYear">{birthYear.text}</label>
		  <select className="FormControl" required name="birthYear" id="birthYear">
		    {options}
		  </select>
		</div>
	    </div>, 30);


	    items.add('state', <div className="Form-group">
	      <label for="state">{state.text}</label>
	      <select className="FormControl" name="State" id="state">
	      	<option value="">{state.text}</option>
	      	<option selected={this.props.user.state() == 'AG'} value="AG">Aargau</option>
	      	<option selected={this.props.user.state() == 'AR'} value="AR">Appenzell Ausserrhoden</option>
	      	<option selected={this.props.user.state() == 'AI'} value="AI">Appenzell Innerrhoden</option>
	      	<option selected={this.props.user.state() == 'BL'} value="BL">Basel-Landschaft</option>
			<option selected={this.props.user.state() == 'BS'} value="BS">Basel-Stadt</option>
	      	<option selected={this.props.user.state() == 'BE'} value="BE">Bern</option>
	      	<option selected={this.props.user.state() == 'FR'} value="FR">Fribourg - Freiburg</option>
	      	<option selected={this.props.user.state() == 'GE'} value="GE">Genève</option>
	      	<option selected={this.props.user.state() == 'GL'} value="GL">Glarus</option>
	      	<option selected={this.props.user.state() == 'GR'} value="GR">Graubünden</option>
	      	<option selected={this.props.user.state() == 'JU'} value="JU">Jura</option>
	      	<option selected={this.props.user.state() == 'LU'} value="LU">Luzern</option>
	      	<option selected={this.props.user.state() == 'NE'} value="NE">Neuchâtel</option>
	      	<option selected={this.props.user.state() == 'NW'} value="NW">Nidwalden</option>
	      	<option selected={this.props.user.state() == 'OW'} value="OW">Obwalden</option>
	      	<option selected={this.props.user.state() == 'SH'} value="SH">Schaffhausen</option>
	      	<option selected={this.props.user.state() == 'SZ'} value="SZ">Schwyz</option>
			<option selected={this.props.user.state() == 'SO'} value="SO">Solothurn</option>
			<option selected={this.props.user.state() == 'SG'} value="SG">St. Gallen</option>
			<option selected={this.props.user.state() == 'TG'} value="TG">Thurgau</option>
			<option selected={this.props.user.state() == 'TI'} value="TI">Ticino</option>
			<option selected={this.props.user.state() == 'UR'} value="UR">Uri</option>
			<option selected={this.props.user.state() == 'VS'} value="VS">Valais - Wallis</option>
			<option selected={this.props.user.state() == 'VD'} value="VD">Vaud</option>
			<option selected={this.props.user.state() == 'ZG'} value="ZG">Zug</option>
			<option selected={this.props.user.state() == 'ZH'} value="ZH">Zürich</option>
			<option selected={this.props.user.state() == 'OTHER'} value="OTHER">{state.other}</option>
	      </select>
	    </div>, 30);

	    items.add('sexe', <div className="Form-group">
	      <label for="sexe">{sexe.text}</label>

	      <div className="Form-group sexe">
	        <label className="checkbox" for="sexeFemale"><input type="radio" checked={this.props.user.sexe() == 'F'} className="FormRadio" value="F" name="sexe" id="sexeFemale"/> {sexe.female}</label>
	      	<label className="checkbox" for="sexeMale"><input type="radio" checked={this.props.user.sexe() == 'M'} className="FormRadio" value="M" name="sexe" id="sexeMale"/> {sexe.male}</label>
	    	<label className="checkbox" for="sexeOther"><input type="radio" checked={this.props.user.sexe() == 'O'} className="FormRadio" value="O" name="sexe" id="sexeOther"/> {sexe.other}</label>
	      </div>
	    </div>, 30);
	});

	extend(EditUserModal.prototype, 'data', function (data) {
		data.birthYear = document.getElementById("birthYear").value;
		data.state = document.getElementById("state").value;
		if (document.getElementById("sexeMale").checked) {
			data.sexe = "M";
		} else if (document.getElementById("sexeFemale").checked) {
			data.sexe = "F";
		} else if (document.getElementById("sexeOther").checked) {
			data.sexe = "O";
		}

		return data;
	});

});
